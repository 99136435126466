.HeroSection {
    background-color: black;
    position: relative;
}

.HeroMobile {
    width: 100vw;
    height: 85vh;
    object-fit: cover;
    opacity: 65%;
}

.HeroSection video {
    width: 100vw;
    height: 85vh;
    object-fit: cover;
    opacity: 65%;
}

.HeroMainContainer::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60%;
    background: linear-gradient(to top, 
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 0.9) 30%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0) 100%);
    pointer-events: none;
}

.HeroLogoContainer {
    position: absolute;
    width: 100vw;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding-top: 3em;
}

.HeroLogoContainer img {
    height: 65vh;
}


@media (max-width: 700px) {
    .HeroLogoContainer {
        align-items: center;
    }
    .HeroLogoContainer img {
        height: 40vh;
    }
    .HeroSection video {
        height: 50vh;
    }

}
@media (max-width: 900px) {
    .HeroSection video {
        height: 85vh;
    }                   
}