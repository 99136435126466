.HomePageSloganContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.HomePageSloganContainer img {
    width: 80%;
}

@media (max-width: 999px){
    .HomePageSloganContainer img {
        width: 80%;
        padding: 3em 0;
    }
}

@media (min-width: 1000px) and (max-width: 1499px) {
    .HomePageSloganContainer img {
        width: 80%;
        padding: 5em 0;
    }
}

@media (min-width: 1500px) {
    .HomePageSloganContainer img {
        width: 80%;
        padding: 5em 0;
    }
}
