.InfoWindow {
    text-align: center;
    font-size: 1.5em;
    font-family: 'Alegreya Sans SC';
    font-weight: 500;
}

.MapButtonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}


.MapButtonContainer button {
    border-radius: 5px;
    padding: 5px;
    color: white;
    background-color: var(--glampink);
    transition: all .3s ease;
}

.MapButtonContainer button:hover {
    background-color: var(--glamblue);
}