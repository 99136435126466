.FormFieldBottom {
    padding-top: 30px;
}

.FormFieldBottom p {
    color: var(--glamwhite);
    font-family: 'Alegreya Sans SC';
    font-size: 1.5em;
    font-weight: 100;
}

input[type=file] {
    color: var(--glamblue);
    font-family: "Alegreya Sans SC";
}

input[type=file]::file-selector-button  {
    font-family: "Alegreya Sans SC";
    font-size: 1.3em;
    padding: .5em;
    border: 2px solid var(--glamblue);
    border-radius: 10px;
    color: var(--glamwhite);
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
}

input[type=file]::file-selector-button:hover {
    background-color: rgba(16, 123, 152, 0.5);
    box-shadow: 0 0 20px var(--glamblue);
}


@media (max-width: 649px) {
    .FormHead {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }
    .FormField input {
        font-size: 1em;
    }
    .FormField textarea {
        font-size: 1em;
    }
    input[type=file]::file-selector-button {
        font-size: 1em;
    }
}

@media (min-width: 650px) and (max-width: 1000px) {
    .FormField input {
        font-size: 1em;
    }
    .FormField textarea {
        font-size: 1em;
    }
    .FormFieldBottom p {
        font-size: 1em;
    }
}


