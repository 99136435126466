.PartnersLogoContainers {
    height: 16em;
    background: linear-gradient(to top, 
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%);
}

.PartnersLogos {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 80vw;
    align-items: center;
    margin: 0 auto;
    position: relative;
}

.PartnersLogos img {
    width: 8em;
}

.FooterImageContainer {
    background-image: url(../../assets//WhiskyAGoGoPhoto.jpg);
    background-size: cover;
    background-position: center;
    width: 100vw;
    position: relative;
    z-index: 1;
}

.FooterImageContainer::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(to bottom,
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 0.5) 75%,
    rgba(0, 0, 0, 0) 100%);
    pointer-events: none;
    z-index: -1;
}

.FooterImageContainer::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(to top, 
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 0.9) 30%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0) 100%);
    pointer-events: none;
    z-index: -1;
}

.SkullDateSocialContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em 0 5em 0;
}

.SkullContainer {
    display: flex;
    justify-content: center;
    width: 35%;
    padding-bottom: 2em;
}

.SkullContainer img {
    width: 40%;
}

.DateContainer {
    width: 50%;
    display: flex;
    justify-content: center;
}

.DateContainer img {
    width: 100%;
}

.SocialsContainerFooter {
    width: 25vw;
    margin: 2em 0 2em 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.SocialsContainer img {
    width: 40px;
    padding: 0 1em 0 1em;
}

.FooterLinksContainer {
    background: black;
    padding-bottom: 2em;
}

.FooterLinks {
    font-family: "Reem Kufi";
    color: var(--glamwhite);
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    text-align: center;
}

.FooterLinks a {
    text-decoration: none; 
    color: inherit;
    cursor: pointer;
    font-size: 1.5em;
}

.FooterLinks a:hover {
    color: var(--glampink);
    text-shadow: var(--glamblue) 0px 0px 10px;;
    transition: color 0.2s ease-in;
}

.FooterLinks span {
    font-family: "Gotham";
    font-weight: lighter;
    font-size: 3.5em;
    color: var(--glampink);
}


/*PartnersLogos*/
@media (max-width: 799px) {
    .PartnersLogos img {
        width: 4em;
    }
}

@media (max-width: 900px) {
    .PartnersLogos {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2em;
        width: 50vw;
        justify-items: center;
        justify-content: center;
        margin: 0 auto;
    };
}

@media (min-width: 800px) and (max-width: 1200px) {
    .PartnersLogos img {
        width: 6em;
    }
}

/*SocialsContainer*/
@media (max-width: 849px) {
    .SocialsContainer{
        display: grid;
        grid-template-columns: repeat(3, auto);
        justify-items: center; 
        align-items: center;
        row-gap: 20px;
        align-self: center;
        width: 50vw;
   }
}

@media (min-width: 850px) and (max-width: 1750px) {
    .SocialsContainer{
        width: 50vw;
        display: flex;
        justify-content: center;
    }
}

/*FooterLinks*/
@media (max-width: 715px) {
    .FooterLinks {
        display: flex;
        flex-direction: column;
    }
    .FooterLinks a:not(:last-child) {
        border-bottom: 1px solid var(--glampink);
    }
    .FooterLinks a {
        padding: 10px 0 10px 0;
    }
    .FooterLinks span {
        display: none;
    }
}

@media (min-width: 716px) and (max-width: 999px) {
    .FooterLinks a {
        font-size: 0.8em;
    }
}

@media (min-width: 1000px) and (max-width: 1550px) {
    .FooterLinks a {
        font-size: 1em;
    }
}