.SectionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, 
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 0.9) 30%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0) 100%);
}

.CountdownContainer {
    pointer-events: none;
    display: grid;
    grid-template-columns:repeat(4,1fr);
    justify-items: center;
    align-items: baseline;
    width: 50vw;
}

.CountdownItem {
    display: grid;
    grid-template-rows: 1fr .5fr;
    justify-items: center;
    align-items: baseline;
    gap: 10px;
}

.CountdownItem h2 {
    font-family: 'Reem Kufi';
    color: var(--glamwhite);
    font-size: 9em;
    margin: 0;
}

.CountdownItem p {
    font-family: 'Alegreya Sans SC';
    color: var(--glamwhite);
    font-weight: 500;
    font-size: 2em;
}

@media (max-width: 600px) {
    .CountdownContainer {
        grid-template-columns: 1fr;
    }
    .CountdownItem h2 {
        font-size: 5em;
    }
    .CountdownContainer p {
        font-size: 1.5em;
    }
}

@media (min-width: 601px) and (max-width: 899px) {
    .CountdownContainer {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 25px;
    }
    .CountdownItem h2 {
        font-size: 4em;
    }
    .CountdownContainer p {
        font-size: 1.5em;
    }
}

@media (min-width: 900px) and (max-width: 1199px) {
    .CountdownItem h2 {
        font-size: 3.5em;
    }
    .CountdownContainer p {
        font-size: 1.5em;
    }
}

@media (min-width: 1200px) and (max-width: 1600px) {
    .CountdownContainer h2 {
        font-size: 5em;
    }
}
