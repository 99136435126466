.FormBottom textarea {
    height: 10em;
    width: 100%;
    margin-top: 30px;
    padding: 1em;
    box-sizing: border-box;
    font-family: "Alegreya Sans SC";
    font-size: 1.6em;
    font-weight: 300;
    color: var(--glamwhite);
    border-radius: 10px;
    border: none;
    background-color: hsla(193, 81%, 33%, 0.5);
    transition: all 0.3s ease;
    border: 1px solid transparent;
}

.FormBottom textarea::placeholder {
    color: var(--glamwhite);
}

.FormBottom textarea:hover,
.FormBottom textarea:focus{
    border: 1px solid var(--glampink);
    outline: 1px solid var(--glampink);;
    box-shadow: 0 0 20px var(--glamblue);
}



@media (max-width: 649px) {
    .FormHead {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }
    .FormField input {
        font-size: 1em;
    }
    .FormField textarea {
        font-size: 1em;
    }
}

@media (min-width: 650px) and (max-width: 1000px) {
    .FormField input {
        font-size: 1em;
    }
    .FormField textarea {
        font-size: 1em;
    }
}


