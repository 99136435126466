
.ParagraphContainer p:last-child {
    margin-bottom: 0;
}

@media (max-width: 700px) {
    .ParagraphContainer p {
        font-size: 1.25em;
    }    
}

@media (min-width: 700px) and (max-width: 1200px) {
    .ParagraphContainer p {
        font-size: 2em;
    }
}