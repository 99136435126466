.BandHeader {
    height: 90vh;
    width: 100vw;
    position: relative;
    display: flex;
    justify-content: center;
}

.BandHeader img {
    height: 90vh;
    width: 100vw;
    object-fit: cover;
    object-position: top;
}

.HeaderBlackFilter {
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, 
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 80%);
}

.BandHeaderTextContainer {
    position: absolute;
    bottom: 5em;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 2em 3em 1em 3em;
    border-radius: 25px;
    border: 2px solid var(--glampink);
    box-shadow: 0 0 20px var(--glamblue);
}

.BandHeaderTextContainer h1 {
    font-family: 'Reem Kufi';
    color: var(--glamwhite);
    font-size: 5em;
    text-align: center;
    margin: 0 0 .3em 0;
    text-shadow: 
    -1px -1px 0 var(--glamblue),
     1px -1px 0 var(--glamblue),
    -1px  1px 0 var(--glamblue),
     1px  1px 0 var(--glamblue);
}

.BandHeaderTextContainer p {
    font-family: 'Reem Kufi';
    color: var(--glamwhite);
    font-size: 2em;
    text-align: center;
    margin: 0;
}

.BandHeader::after {
    content: '';
    position: absolute;
    height: 20vh;
    width: 100%;
    top: 100%;
    bottom: 0;
    left: 0px;
    border-radius: 10px;
    background: linear-gradient(to bottom, 
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.9) 30%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0) 100%);
    z-index: -1;
}

.SpotifySection {
    margin-top: 7.5em;
    display: flex;
    justify-content: center;
    margin-bottom: 5em;
    overflow: hidden;
}

.SpotifySection iframe{
    width: 60%;
    height: 352px;
}

.BandTextSection {
    display: flex;
    justify-content: center;
}

#text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.BandTextSection p{
    width: 60%;
    color: var(--glamwhite);
    font-family: 'Alegreya Sans SC';
    font-size: 2em;
    font-weight: 300;
    text-align: justify;
    margin: .5em;
}

.YoutubeSection {
    display: flex;
    justify-content: center;
    padding: 5em 0 5em 0;
}

.YoutubeSection iframe{
    width: 765px; 
    height: 430px;
}

.PerformanceSection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.PerformanceSection h2 {
    width: 10em;
    font-family: 'Reem Kufi';
    color: white;
    font-size: 2em;
    text-align: center;
    background-color: black;
    border: solid 1px var(--glampink);
    border-radius: 10px;
    padding: .5em;
    margin: 2em 1em 2em 1em;
    box-shadow: 0 0 20px var(--glamblue);
    text-shadow: 
    -1px -1px 0 var(--glampink),
     1px -1px 0 var(--glampink),
    -1px  1px 0 var(--glampink),
     1px  1px 0 var(--glampink);
}


.SocialsSection {
    display: flex;
    justify-content: center;
}

.SocialsBandContainer {
    margin: 5em 0 5em 0;
    width: 20vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.SocialsBandContainer img {
    width: 30px;
    padding: 0 1em 0 1em;
}


@media (max-width: 849px) {
    .BandHeader {
        height: auto;
    }
    .BandHeader img {
        height: auto;
    }
    .BandHeaderTextContainer h1 {
        font-size: 3em;
    }
    .BandHeaderTextContainer p {
        font-size: 1em;
    }
    .YoutubeSection {
        display: flex;
        margin: 0 auto;
        width: 80vw;
    }
    .SocialsBandContainer{
        display: grid;
        grid-template-columns: repeat(3, auto);
        justify-items: center; 
        align-items: center;
        row-gap: 20px;
        align-self: center;
        width: 50vw;
   }
}

@media (max-width: 650px) {
    .BandHeaderTextContainer h1 {
        font-size: 2em;
    }
    .BandHeaderTextContainer p {
        font-size: 1em;
    }
    .BandHeaderTextContainer {
        padding: 1em 2em 1em 2em;
    }
}

@media (min-width: 850px) and (max-width: 1750px) {
    .SocialsContainer{
        width: 50vw;
    }
}

@media (max-width: 699px) {
    .PerformanceSection {
        flex-direction: column;
    }
    .PerformanceSection h2 {
        margin: .5em;
    }
}

@media (min-width: 700px) and (max-width: 999px) {
    .PerformanceSection h2 {
        width: 10em;
        font-size: 1.2em;
        padding: .5em;
        margin: 2em 1em 2em 1em;
    }
}

@media (min-width: 1000px) and (max-width: 1300px) {
    .PerformanceSection h2 {
        width: 10em;
        font-size: 1.5em;
        padding: .5em;
        margin: 2em 1em 2em 1em;
    }
}


