.MapContainer {
  position: relative;
  margin: 0 auto;
  height: 100%;

}

.Filters {
  width: 85%;
}

.FiltersContainer {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  border: solid 2px var(--glampink);
  display: flex;
  flex-direction: column;
  margin: 6em 0 0 10%;
  z-index: 1;
  border-radius: 25px;
  color: white;
  font-family: 'Alegreya Sans SC';
  font-size: 1.2em;
  box-shadow: 0 0 20px var(--glamblue);
}

.FiltersContainer label {
  margin: 30px 40px 0 40px;
  display: flex;
  align-items: center;
}

.FiltersButton {
  margin: 15px auto;
  text-align: center;
  width: 70%;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  border: solid 2px var(--glampink);
  color: white;
  font-family: 'Alegreya Sans SC';
  font-weight: 500;
  transition: all .3s ease;
}

.FiltersButton:hover {
  background-color:var(--glampink);
}

.IconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border-radius: 50%;
  padding: 6px;
  width: 17px;
  height: 17px;
}

.FiltersContainer label img{
  width: 17px;
}

.FiltersContainer input[type="checkbox"] {
  transform: scale(2);
  margin-right: 10px;
}

#map {
  width: 85%;
  height: 75vh;
  min-width: 250px;
  min-height: 750px;
  margin: 1em auto;
}

@media (max-width: 900px) {
  .Filters {
    margin: 0 auto;
  }
  .FiltersContainer {
    position: relative;
    margin: 3em auto;
    flex-direction: row;
    flex-wrap: wrap;
  }
}